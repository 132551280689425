<script setup lang="ts">
  import { useTextareaAutosize, syncRefs } from '@vueuse/core'
  import { ref, computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      modelValue: string | null | undefined
      label: string
      id: string
      placeholder?: string
      rows?: number
      showLabel?: boolean
      autosize?: boolean
      textareaClass?: string
      disabled?: boolean
    }>(),
    {
      placeholder: '',
      rows: 8,
      showLabel: true,
      textareaClass: undefined,
    }
  )

  defineEmits<{
    'update:modelValue': [value: string]
    change: [value: string]
  }>()

  const model = ref<string>('')
  syncRefs(() => props.modelValue, model)

  const textarea = ref<HTMLTextAreaElement>()
  useTextareaAutosize({
    input: model,
    element: computed(() => (props.autosize ? textarea.value : undefined)),
  })
</script>

<template>
  <div class="flex flex-col gap-3">
    <label :class="{ 'sr-only': !showLabel }" :for="id">{{ label }}</label>
    <textarea
      :id
      ref="textarea"
      v-model="model"
      class="base-control gc-global-scrollbar gc-global-scrollbar-thin gc-global-scrollbar-white-var-30 focus:border-white-var bg-black-var border-gray-var-600 placeholder-gray-var-400 disabled:not-allowed resize-none px-4 py-3 text-base focus:outline-none focus:ring-0 focus-visible:outline-none"
      :class="textareaClass"
      data-testid="base-textarea"
      :disabled
      :placeholder
      :rows
      @change="$emit('change', model)"
      @input="$emit('update:modelValue', model)"
    />
  </div>
</template>
